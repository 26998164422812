import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import ConditionalWrapper from "src/components/helpers/ConditionalWrapper";
import getTitleId from "../helpers/getTitleId";
import { AccruentImg } from "../commonComponents";
const defaultImage = "../../../static/teaser_default.jpg";

const InnerBullet = ({ bullet, index }) => {
  return (
    <div className="columns">
      <div className="column is-1 is-text-kingfisher-daisy has-text-weight-bold">
        0{index + 1}
      </div>
      <div className="column">
        {!bullet.hideTitle && (
          <h6 className="main-title mb-2 is-family-secondary">
            {bullet.title}
          </h6>
          // <div className="has-text-weight-bold mb-2">{bullet.title}</div>
        )}
        <div>{bullet.body}</div>
      </div>
    </div>
  );
};

const Features = ({
  image,
  alt,
  bullets,
  title,
  sectionBody,
  hasTitleAndBodyCentered,
}) => {
  return (
    <>
      {title && (
        <h2
          className={cn("title-4", {
            "has-text-centered": hasTitleAndBodyCentered,
          })}
          id={getTitleId(title)}
        >
          {title}
        </h2>
      )}
      {sectionBody && (
        // Todo: Why h6 has children?
        <h6>
          <ConditionalWrapper
            condition={hasTitleAndBodyCentered}
            wrapper={(children) => (
              <div className="columns">
                <div className="column is-10 is-offset-1-tablet has-text-centered">
                  {children}
                </div>
              </div>
            )}
          >
            <div
              className="mt-3 mb-5 features-section-body tw-features-section-body dangerously-html-handle"
              dangerouslySetInnerHTML={{ __html: sectionBody }}
            />
          </ConditionalWrapper>
        </h6>
      )}
      <div className="features-wrapper tw-features-wrapper -lg:tw-relative tw-mb-[2em]" component="Features">
        <div className="-lg:tw-max-w-[80%] -lg:tw-mb-[2em] -lg:tw-mx-auto">
          {image ? (
            <AccruentImg
              file={image}
              objectFit="contain"
              className="tw-max-h-[800px]"
            />
          ) : (
            <StaticImage
              src={defaultImage}
              objectFit="contain"
              className="tw-max-h-[800px]"
              aspectRatio={1.3}
              alt={alt}
              decoding = "async"
            />
          )}
        </div>
        <div className="features-bullets tw-features-bullets -md:tw-mb-6 tw-absolute tw-max-w-[22em] hover:tw-z-[1]">
          {bullets?.map((bullet, index) => {
            if (index + 1 <= 4) {
              return (
                <div
                  className={`box features-bullet tw-features-bullet bullet-${index + 1}`}
                  key={bullet.body+index}
                >
                  <InnerBullet bullet={bullet} index={index} />
                </div>
              );
            } else return null;
          })}
        </div>
      </div>
      {bullets?.length > 4 && (
        <div className="columns is-centered is-multiline">
          {bullets?.map((bullet, index) => {
            if (index > 3) {
              return (
                <div className="column is-4" key={bullet.body+index}>
                  <div className="box remaining-bullets">
                    <InnerBullet bullet={bullet} index={index} />
                  </div>
                </div>
              );
            } else return null;
          })}
        </div>
      )}
    </>
  );
};

export default Features;
